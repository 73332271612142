import { NextPage } from 'next'
import { useRouter } from 'next/router'
import * as Sentry from '@sentry/nextjs'

import LoadingSpinner from 'components/v2/common/LoadingSpinner'

import { HXAP } from 'lib/bridge'

import { getStoreList } from 'lib/api/store/getStoreList'
import { getJobSearch } from 'lib/api/jobSearch/getJobSearch'
import { getResume } from 'lib/api/resume/getResume'
import { StoreListType } from 'lib/api/store/types'

import { useAppDispatch } from 'hooks/useReduxHook'
import { useTokenValidation } from 'hooks/useTokenValidation'
import { useEnterStore } from 'hooks/useEnterStore'

import { EnteredStoreStateType } from 'store/modules/enteredStoreState'
import { setDeletedStoreInfo } from 'store/modules/deletedEnteredStore'

const InitPage: NextPage = () => {
  const { replace } = useRouter()

  const { enterStore } = useEnterStore()
  const dispatch = useAppDispatch()

  const gotoMainPage = async () => {
    const tab = await HXAP.loadData<{ latestTab: string }>('latestTab')
    if (!!tab) {
      replace(`/${tab.latestTab}`)
    } else {
      replace('/todayChecklist')
    }
  }

  // const noStoreUserPush = async () => {
  //   const jobSearch = await getJobSearch()
  //   if (!jobSearch) {
  //     replace('/initialSetting/userType')
  //   } else {
  //     const resume = await getResume()
  //     if (!resume) {
  //       replace('/initialSetting/partTimer/middleGuide')
  //     } else {
  //       replace('/todayRecommend')
  //     }
  //   }
  // }

  const noLocalStoreUserPush = async (storeList: StoreListType) => {
    const latestJoinStore = storeList.sort((a, b) => {
      const timeA = new Date(a.member_join_date).getTime()
      const timeB = new Date(b.member_join_date).getTime()

      return timeB > timeA ? 1 : -1
    })[0]

    const enteredStore: EnteredStoreStateType = {
      storeId: latestJoinStore.store_id,
      storeName: latestJoinStore.store_name,
    }

    await enterStore(enteredStore)
    await gotoMainPage()
  }

  const handleValidationPass = async () => {
    try {
      const storeList = await getStoreList()
      if (storeList.length <= 0) {
        await replace('/todayChecklist')
        return
      }

      const enteredStoreJson = await HXAP.loadData<string>('enteredStore')
      if (!enteredStoreJson) {
        await noLocalStoreUserPush(storeList)
        return
      }

      let enteredStore: EnteredStoreStateType = JSON.parse(enteredStoreJson)
      if (
        !storeList.find(({ store_id }) => store_id === enteredStore.storeId)
      ) {
        dispatch(setDeletedStoreInfo(enteredStore))
        await noLocalStoreUserPush(storeList)
        return
      }
      await enterStore(enteredStore)
      await gotoMainPage()
    } catch (error) {
      replace('/auth/welcome')
      Sentry.captureException(error, {
        extra: {
          type: 'auto logout',
        },
      })
    }
  }

  useTokenValidation({
    onPass: handleValidationPass,
    onExpired: () => {
      replace('/auth/welcome')
    },
    options: {
      userOnlyYn: 'Y',
    },
  })
  return <LoadingSpinner />
}

export default InitPage
