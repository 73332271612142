import { useEffect } from 'react'
import { putToken, PutTokenReq } from 'lib/api/auth/putToken'
import { HXAP } from 'lib/bridge'
import { isTokenExpired } from 'lib/utils/common/isTokenExpired'
import { useRouter } from 'next/router'
import { JwtTokenType } from 'lib/api/types'
import { setLoginState } from 'lib/utils/login/loginState'

interface UseTokenValidationParams {
  onPass: () => Promise<void> | void
  onExpired: () => Promise<void> | void
  options?: Omit<PutTokenReq, 'refresh_token'>
}

// Android에서 useTokenValidation이 2번 호출되는 문제가 있음...
let _tokenValidationMutex: boolean = false

export function useTokenValidation({
  options,
  onPass,
  onExpired,
}: UseTokenValidationParams) {
  const { replace } = useRouter()

  const checkUserToken = async (): Promise<boolean> => {
    const tokens = await HXAP.loadData<JwtTokenType>('jwt')
    if (!tokens) {
      return false
    }

    // 토큰이 만료되지 않았다면
    if (!isTokenExpired(tokens.refreshToken)) {
      const newTokens = await putToken({
        ...options,
        refresh_token: tokens.refreshToken,
      })

      await HXAP.saveData<JwtTokenType>('jwt', {
        refreshToken: newTokens.refreshToken,
        accessToken: newTokens.accessToken,
      })
      return true
    }
    return false
  }

  const execute = async () => {
    try {
      const isTokenValid = await checkUserToken()
      if (isTokenValid) {
        setLoginState(true)
        await onPass()
        return
      }
      await onExpired()
    } catch {
      replace('/auth/welcome')
    }
  }

  useEffect(() => {
    if (typeof window === 'undefined') return
    if (_tokenValidationMutex) return

    _tokenValidationMutex = true
    execute()
  }, [])
}
