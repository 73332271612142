import axios from 'axios'
import { SignTokenType } from './types'
import { setHXATCookie } from 'lib/utils/axios/setHXATCookie'

export async function putToken(req: PutTokenReq) {
  const response = await axios.put<SignTokenType>(`/v1/auth/token`, req, {
    baseURL: process.env.NEXT_PUBLIC_BASE_URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  })
  const accessToken = response.data.accessToken
  setHXATCookie(accessToken)

  return response.data
}

export type PutTokenReq = {
  refresh_token: string
  store_id?: number
  userOnlyYn?: 'Y' | 'N'
  push_id?: string
}
